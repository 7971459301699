/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

@font-face {
  font-family: 'Anton';
  src: url('./assets/fonts/Anton-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-attachment: fixed;
  background-image: url('/assets/field.png');
  background-color: #004000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y: 27px;
}

.container__team-selection-bar {
  .p-dropdown {
    width: 243px;
  }
}


.schedule__toolbar-picker {
  .p-dropdown {
    min-width: 4rem;
    max-width: 4rem;
    // width: 4rem;
    padding: 1px;
    background-color: lightgray;
    border-left: none;
    border-right: none;
    border-top: none;
    .p-dropdown-trigger {
      width: 1.25rem;
    }
  }
}


.p-inputtext, .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 1px .5rem;;
}

p-inputSwitch {
  display: inline-flex;
  vertical-align: middle;
  padding-right: 24px;
}

.p-tabview {
  .p-tabview-panels {
    min-height: 1000px;
  }
  .p-tabview-nav {
    border-bottom: 2px solid lightgray;
    a[aria-selected="true"] {
      color: #155701 !important;
      background-color: lightgray !important;
    }
    li .p-tabview-nav-link {
      border-bottom: 1px solid;
    }
  }
}
.p-dialog {
  height: 50%;
  .p-dialog-content {
    height: 500px !important;
  }
}

.brand-name {
  font-size: 1.5rem;
  vertical-align: middle;
}
.brand-logo {
  width: 70px;
  vertical-align: bottom;
  vertical-align: middle;
  padding-right: 5px;
}

body {
  background-color: #004000;
}

:host ::ng-deep .p-password input {
  width: 100%;
}

:host {
    font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 { margin: 8px 0; }

  p { margin: 0; }

  .active {
    position:absolute;
    border-left: 12rem solid transparent;
    border-right: 12rem solid transparent;
    border-top: 10rem solid transparent;
    border-bottom: 10px solid #134d00;
    border-radius: 30px;
    transform: rotate(180deg);
  }

  .inset_toolbar-background {
    position: fixed;
    z-index: 19;
    background-color: #004000;
    top: env(safe-area-inset-top);
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right);
    height: calc(2rem + env(safe-area-inset-top));
    margin-top: calc(0px - env(safe-area-inset-top));
  }
  .version__last-prod-push {
    text-align: center;
    font-size: small;
  }

  .toolbar {
    height: 5rem;
    z-index:20;
    position: fixed;
    top: env(safe-area-inset-top);
    left: env(safe-area-inset-left);
    right: env(safe-area-inset-right);
    background-color: #004000;
    color: white;
    align-content: center;
    vertical-align: middle;
    display: grid;
    grid-template-columns: 7rem max-content auto repeat(3, 4rem);
    grid-template-areas: "logo name . schedule predict admin";
    box-shadow: 0px 4px 5px -3px black;

    &--logo {
      grid-area: logo;
      margin: 0 auto;
      align-self: center;
      width: 5rem;
    }
    &--name {
      grid-area: name;
      font-family: "Anton";
      font-size: 3rem;
      padding: 0 1rem;
      justify-content: center;
      display: inline;
      color: #c4c4c4;
    }
    &--schedule {
      grid-area: schedule;
      text-align: center;
      color: #c4c4c4;
      background-color: rgba(136, 136, 136, 0.44);
      border-radius: 3px;
      margin: 0.1rem 0.15rem;
      align-self: center;
      padding: .25rem 0rem;
      .fa {
        font-size: 2rem;
        vertical-align: middle;
        padding-left: 1px;
      }
    }
    &--predict {
      grid-area: predict;
      text-align: center;
      color: #c4c4c4;
      background-color: rgba(136, 136, 136, 0.44);
      border-radius: 3px;
      margin: 0.1rem 0.15rem;
      align-self: center;
      padding: .25rem 0rem;
      .fa-solid {
        font-size: 2rem;
        vertical-align: middle;
        padding-left: 1px;
      }
    }
    &--admin {
      grid-area: admin;
      text-align: center;
      color: rgb(92, 66, 49);
      background-color: rgba(136, 136, 136, 0.44);
      border-radius: 3px;
      margin: 0.1rem 0.15rem;
      align-self: center;
      padding: .25rem 0rem;
      .fa {
        font-size: 2rem;
        vertical-align: middle;
      }
    }
  }
  .content {
    display: flex;
    margin: 82px auto 32px;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 896px) {
    .p-dropdown { width: 16em; }
    .p-dropdown-items-wrapper { max-height: 12rem !important; }
    .toolbar {
      height: 3rem;
      z-index:20;
      position: fixed;
      top: env(safe-area-inset-top);
      left: env(safe-area-inset-left);
      right: env(safe-area-inset-right);
      background-color: #004000;
      color: white;
      align-content: center;
      vertical-align: middle;
      display: grid;
      grid-template-columns: 3rem max-content auto repeat(3, 2rem);
      grid-template-areas: "logo name . schedule predict admin";
  
      &--logo {
        grid-area: logo;
        margin: 0 auto;
        align-self: center;
        width: 40px;
      }
      &--name {
        grid-area: name;
        font-family: "Anton";
        font-size: 20px;
        padding: 0;
        justify-content: center;
        display: inline;
        color: #c4c4c4;
      }
      &--schedule {
        grid-area: schedule;
        text-align: center;
        color: #c4c4c4;
        background-color: rgba(136, 136, 136, 0.44);
        border-radius: 3px;
        margin: auto 2px;
        padding: .05rem 0rem;
        .fa {
          font-size: 20px;
          vertical-align: text-top;
          padding-left: 1px;
        }
      }
      &--predict {
        grid-area: predict;
        text-align: center;
        color: #c4c4c4;
        background-color: rgba(136, 136, 136, 0.44);
        border-radius: 3px;
        margin: auto 2px;
        padding: .05rem 0rem;
        .fa-solid {
          font-size: 20px;
          vertical-align: text-top;
          padding-left: 1px;
        }
      }
      &--admin {
        grid-area: admin;
        text-align: center;
        color: rgb(92, 66, 49);
        background-color: rgba(136, 136, 136, 0.44);
        border-radius: 3px;
        margin: auto 2px;
        padding: .05rem 0rem;
        .fa {
          font-size: 20px;
          vertical-align: text-top;
          padding-left: 1px;
        }
      }
    }
    .content {
      display: flex;
      padding: 0;
      flex-direction: column;
      align-items: center;
      margin: 3rem 0;
    }
  }